import config from '../config';

const { API_URL } = config;

const mediaGetter = (slug) => {
  const a = document.createElement('a');
  a.href = slug;
  const mediaEndpoint = `${API_URL}media${a.search}`;
  return new Promise((resolve) => {
    fetch(mediaEndpoint)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        resolve(data);
      });
  });
};

export default mediaGetter;
