import React, { Component } from 'react';
import Parallax from 'react-rellax';
import pageGetter from '../../Middleware/pageGetter';
import Loader from './Loader';

class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
  }

  componentDidMount() {
    pageGetter('accueil').then((data) => {
      this.setState({ data });
    });
  }

  scrollTo(e) {
    const { section } = this.props;
    e.preventDefault();
    window.scrollTo({ behavior: 'smooth', top: section.accueil.current.offsetTop });
  }

  render() {
    const { data } = this.state;

    if (!data) {
      return <Loader />;
    }

    return (
      <section id="banner">
        <div className="img__container">
          <img src={data.picture_banner.url} alt="caravane potager régal regal" />
        </div>
        <Parallax
          as="div"
          speed={2}
          className="d-flex align-items-center justify-content-center vh-100"
        >
          <div data-aos-duration="1000" data-aos="custom-fade-up" style={{ position: 'relative' }}>
            <div className="logo__container">
              <img src={data.logo.url} alt={data.logo.alt} />
            </div>
            <div className="arrow__container">
              <span />
              <span />
              <span />
            </div>
            <div
              role="menuitem"
              tabIndex="0"
              className="arrow__clickable"
              onClick={(e) => {
                return this.scrollTo(e);
              }}
              onKeyDown={(e) => {
                return this.scrollTo(e);
              }}
            />
          </div>
        </Parallax>
      </section>
    );
  }
}

export default Banner;
