import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import unorm from 'unorm';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggled: false,
    };
  }

  burgerHandler() {
    const { toggled } = this.state;
    let className = 'burger__icon';
    className += toggled ? ' js-toggle--active' : ' js-toggle--inactive';
    return className;
  }

  navbarHandler() {
    const { toggled } = this.state;
    let className = 'navbar toggle';
    className += toggled ? ' js-toggle--active' : ' js-toggle--inactive';
    return className;
  }

  toggleHandler() {
    this.setState((prevState) => {
      return { toggled: !prevState.toggled };
    });
  }

  render() {
    const items = ['Accueil', 'Engagements', 'Notre carte', 'Traiteur', 'Galerie', 'Contact'];
    const itemsID = [];
    const listItem = items.map((item) => {
      const itemID = item
        .split(' ')
        .join('-')
        .toLowerCase()
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
      unorm.nfc(item);
      itemsID.push(itemID);
      return (
        <NavLink
          className="navbar__item"
          activeClassName="current__item"
          key={item.toString()}
          exact
          to={itemID === 'accueil' ? '/' : `/${itemID}`}
          onClick={() => { this.setState({ toggled: false }); }}
        >
          {' '}
          {item}
        </NavLink>
      );
    });

    return (
      <header className={this.navbarHandler()}>
        {listItem}
        <div
          role="menuitem"
          tabIndex="0"
          className={this.burgerHandler()}
          onClick={() => {
            return this.toggleHandler();
          }}
          onKeyDown={() => {
            return this.toggleHandler();
          }}
        >
          <input type="checkbox" />
          <span />
          <span />
          <span />
        </div>
      </header>
    );
  }
}

export default Header;
