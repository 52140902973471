import React, { Component } from 'react';
import {
  Container, Row, Col, InputGroup, FormControl, Button, FormLabel,
} from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import qs from 'qs';
import axios from 'axios';
import pageGetter from '../../Middleware/pageGetter';
import phoneIcon from '../../assets/phone.svg';
import Loader from '../Partials/Loader';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      name: '',
      email: '',
      message: '',
      captchaResponse: false,
      emailValidation: null,
      formResponseStatus: null,
    };
  }

  componentDidMount() {
    pageGetter('contact').then((data) => {
      this.setState({ data });
    });
  }

  emailValidation() {
    const { emailValidation } = this.state;
    switch (emailValidation) {
      case null:
        return null;
      case true:
        return 'valid';
      case false:
        return 'invalid';
      default:
        return null;
    }
  }

  formResponseStatus() {
    const { formResponseStatus } = this.state;

    switch (formResponseStatus) {
      case null:
        return null;
      case 200:
        return 'success';
      default:
        return 'error';
    }
  }

  handleButton() {
    const { emailValidation, captchaResponse } = this.state;
    if (emailValidation && captchaResponse) {
      return 'valid';
    }
    return 'invalid';
  }

  handleCaptcha(value) {
    this.setState({ captchaResponse: value });
  }

  handleEmailChange(e) {
    const regex = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
    const { value } = e.target;
    this.setState({ email: value });
    if (value.length === 0) {
      this.setState({ emailValidation: null });
    } else {
      this.setState({ emailValidation: regex.test(value) });
    }
  }

  handleMessageChange(e) {
    this.setState({ message: e.target.value });
  }

  handleNameChange(e) {
    this.setState({ name: e.target.value });
  }

  successMessage() {
    const { formResponseStatus } = this.state;
    switch (formResponseStatus) {
      case null:
        return 'ENVOYER';
      case 200:
        return 'Merci !';
      default:
        return 'Erreur, vous pouvez me contacter directement à : coraline@lepotagerregal.fr';
    }
  }

  submitSubscription(e) {
    let isEnterButton;
    let isClickSubmit;
    const {
      name, email, message, captchaResponse,
    } = this.state;
    switch (e.type) {
      case 'keydown':
        e.which === 13 ? (isEnterButton = true) : (isEnterButton = false);
        break;

      case 'click':
        isClickSubmit = true;
        break;

      default:
        isEnterButton = false;
        isClickSubmit = false;
        break;
    }
    if (isEnterButton || isClickSubmit) {
      const data = {
        name,
        email,
        message,
        captchaResponse,
      };
      const options = {
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify(data),
        url: '/form-contact.php',
      };
      axios(options).then((res) => {
        this.setState({ formResponseStatus: res.status });
      });
    }
  }

  render() {
    const {
      data, name, email, message, emailValidation, captchaResponse,
    } = this.state;
    const { section } = this.props;
    if (!data) {
      return <Loader />;
    }
    return (
      <section id="contact" ref={section}>
        <div className="main__content">
          <Container>
            <Row as="h1" className="justify-content-center">
              <mark>CONTACT</mark>
            </Row>
            <Row>
              <Col className="m-auto">
                <form data-aos="fade-right" data-aos-duration="800">
                  <FormControl
                    placeholder="VOTRE NOM"
                    aria-label="VOTRE NOM"
                    type="text"
                    value={name}
                    onChange={(e) => {
                      return this.handleNameChange(e);
                    }}
                  />
                  <InputGroup>
                    <FormControl
                      className={[this.emailValidation(), 'js-email-control']}
                      placeholder="VOTRE ADRESSE EMAIL"
                      aria-label="VOTRE ADRESSE EMAIL"
                      type="email"
                      value={email}
                      onChange={(e) => {
                        return this.handleEmailChange(e);
                      }}
                      onKeyDown={(e) => {
                        return this.submitSubscription(e);
                      }}
                    />
                  </InputGroup>
                  <FormLabel className={[this.emailValidation(), 'js-alert-control']}>
                    <p className="text-center">L'email renseigné n'est pas valide</p>
                  </FormLabel>
                  <FormControl
                    as="textarea"
                    placeholder="VOTRE DEMANDE"
                    aria-label="VOTRE DEMANDE"
                    type="text"
                    value={message}
                    onChange={(e) => {
                      return this.handleMessageChange(e);
                    }}
                  />
                  <ReCAPTCHA
                    sitekey="6LfohqMUAAAAACHqMs5nJGphEbj6WGH8di1VvZod"
                    onChange={(e) => {
                      return this.handleCaptcha(e);
                    }}
                    className="pb-4"
                  />
                  <InputGroup.Append className="justify-content-start">
                    <Button
                      type="button"
                      className={[this.handleButton(), this.formResponseStatus(), 'btn--custom']}
                      onClick={(e) => {
                        return this.submitSubscription(e);
                      }}
                      disabled={!emailValidation || !captchaResponse}
                    >
                      {this.successMessage()}
                    </Button>
                  </InputGroup.Append>
                </form>
              </Col>
              <Col
                data-aos-duration="900"
                data-aos="fade-up"
                className="d-none d-md-block"
              >
                <div className="align-items-center justify-content-center d-md-flex h-100">
                  <div className="img__container ">
                    <img src={data.picture_contact.url} alt={data.picture_contact.alt} />
                    <div className="img__clone--yellow" />
                  </div>
                </div>
              </Col>
            </Row>
            <Row
              className="justify-content-center content__phone "
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="800"
            >
              <span className="icon__container">
                <img src={phoneIcon} alt="phone icon" />
              </span>
              <span className="d-flex align-items-center">
                <i>{data.phone}</i>
              </span>
            </Row>
          </Container>
        </div>
      </section>
    );
  }
}

export default Contact;
