import React, { Component } from 'react';
import { Container, Row, Carousel } from 'react-bootstrap';
import pageGetter from '../../Middleware/pageGetter';
import mediaGetter from '../../Middleware/mediaGetter';
import config from '../../config';
import leftArrow from '../../assets/left-arrow.svg';
import rightArrow from '../../assets/right-arrow.svg';
import Loader from '../Partials/Loader';

const { API_URL_BASE } = config;

class Galerie extends Component {
  constructor(props) {
    super(props);
    this.state = {
      medias: null,
    };
  }

  componentDidMount() {
    pageGetter('galerie', 'WP').then((data) => {
      // eslint-disable-next-line no-underscore-dangle
      mediaGetter(data._links['wp:attachment'][0].href).then((mediaData) => {
        const medias = [];
        mediaData.map((element) => {
          const a = document.createElement('a');
          const media = {};
          let caption = element.caption.rendered;
          a.href = element.source_url;
          caption = caption.replace('<p>', '');
          caption = caption.replace('</p>', '');
          media.src = API_URL_BASE + a.pathname;
          media.caption = caption;
          return medias.push(media);
        });
        return this.setState({ medias });
      });
    });
  }

  render() {
    const { medias } = this.state;
    const { section } = this.props;

    if (!medias) {
      return <Loader />;
    }

    const mediaList = medias.map((media) => {
      return (
        <Carousel.Item key={media.src.toString()}>
          <img className="d-block w-100" src={media.src} alt={media.caption} />
          <Carousel.Caption>
            <h3>{media.caption}</h3>
          </Carousel.Caption>
        </Carousel.Item>
      );
    });

    return (
      <section id="galerie" ref={section}>
        <div className="main__content">
          <Container>
            <Row as="h1" className="justify-content-center">
              <mark>GALERIE</mark>
            </Row>
            <Row
              className="justify-content-center"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="400"
            >
              <Carousel
                prevIcon={<img src={leftArrow} alt="fleche gauche" />}
                nextIcon={<img src={rightArrow} alt="fleche droite" />}
              >
                {mediaList}
              </Carousel>
            </Row>
          </Container>
        </div>
      </section>
    );
  }
}

export default Galerie;
