import config from '../config';

const { API_URL, API_MODULES } = config;

const mapGetter = (slug, paramModule) => {
  let upperModule = API_MODULES;
  if (paramModule) { upperModule = paramModule; }
  return new Promise((resolve) => {
    fetch(`${API_URL}pages?slug=${slug}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        switch (upperModule) {
          case 'ACF':
            resolve(data[0].acf);
            break;
          case 'WP':
            resolve(data[0]);
            break;
          default:
            resolve(data[0]);
            break;
        }
      });
  });
};

export default mapGetter;
