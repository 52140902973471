import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './styles/app.scss';

import Header from './Components/Partials/Header';
import Banner from './Components/Partials/Banner';
import Accueil from './Components/Pages/Accueil';
import Engagements from './Components/Pages/Engagements';
import Carte from './Components/Pages/Carte';
import Traiteur from './Components/Pages/Traiteur';
import Contact from './Components/Pages/Contact';
import Galerie from './Components/Pages/Galerie';

class App extends Component {
  constructor(props) {
    super(props);
    this.section = {
      accueil: React.createRef(),
      engagements: React.createRef(),
      carte: React.createRef(),
      contact: React.createRef(),
    };
  }

  componentDidMount() {
    AOS.init({ once: true });
  }

  render() {
    return (
      <main className="App">
        <Header section={this.section} />
        <Route
          render={({ location }) => {
            return (
              <TransitionGroup>
                <CSSTransition key={location.key} timeout={450} classNames="fade">
                  <Switch location={location}>
                    <Route
                      exact
                      path="/"
                      render={(props) => {
                        return (
                          <section>
                            <Banner {...props} section={this.section} />
                            <Accueil {...props} section={this.section.accueil} />
                          </section>
                        );
                      }}
                    />
                    <Route path="/engagements" component={Engagements} />
                    <Route path="/notre-carte" component={Carte} />
                    <Route path="/traiteur" component={Traiteur} />
                    <Route path="/galerie" component={Galerie} />
                    <Route path="/contact" component={Contact} />
                  </Switch>
                </CSSTransition>
              </TransitionGroup>
            );
          }}
        />
      </main>
    );
  }
}

export default App;
