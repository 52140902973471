import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import pageGetter from '../../Middleware/pageGetter';
import Loader from '../Partials/Loader';

class Traiteur extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
  }

  componentDidMount() {
    pageGetter('traiteur').then((data) => {
      this.setState({ data });
    });
  }

  render() {
    const { data } = this.state;
    const { section } = this.props;
    if (!data) {
      return <Loader />;
    }
    return (
      <section id="traiteur" ref={section}>
        <div className="main__content">
          <Container>
            <Row as="h1" className="justify-content-center">
              <mark>TRAITEUR</mark>
            </Row>
            <Row>
              <div className="main__row__responsive d-flex">
                <Col
                  data-aos="fade-up"
                  data-aos-duration="800"
                  data-aos-delay="400"
                >
                  <div className="d-flex align-items-center h-100">
                    <div className="img__container">
                      <img
                        src={data.picture_traiteur.url}
                        alt={data.picture_traiteur.alt}
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="d-flex align-items-center responsive__col">
                    <p
                      data-aos="fade-left"
                      data-aos-duration="1000"
                      dangerouslySetInnerHTML={{ __html: data.description_traiteur }}
                    />
                  </div>
                </Col>
              </div>
            </Row>
          </Container>
        </div>
      </section>
    );
  }
}

export default Traiteur;
