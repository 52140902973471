import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Parallax from 'react-rellax';
import pageGetter from '../../Middleware/pageGetter';
import pineappleBackground from '../../assets/pineapple-background.jpg';
import Loader from '../Partials/Loader';

class Presentation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
  }

  componentDidMount() {
    pageGetter('accueil').then((data) => {
      this.setState({ data });
    });
  }

  render() {
    const { data } = this.state;
    const { section } = this.props;
    if (!data) {
      return <Loader />;
    }
    return (
      <section id="accueil" ref={section}>
        <Parallax as="div" speed={-1} className="img__container">
          <img src={pineappleBackground} alt="ananas background" />
        </Parallax>
        <div className="main__content">
          <Container>
            <Row as="h1" className="justify-content-center">
              <mark>PRÉSENTATION</mark>
            </Row>
            <Row>
              <div className="main__row__responsive d-flex">
                <Col>
                  <div className="d-flex align-items-center responsive__col">
                    <p
                      data-aos="fade-left"
                      data-aos-duration="1000"
                      dangerouslySetInnerHTML={{ __html: data.description_activity }}
                    />
                  </div>
                </Col>
                <Col data-aos-duration="900" data-aos="fade-up">
                  <div className="d-flex align-items-center h-100">
                    <div className="img__container__profile">
                      <img
                        src={data.picture_team.url}
                        alt={data.picture_team.alt}
                        className="img-fluid mx-auto d-block"
                      />
                      <div className="img-caption text-center">
                        <div>
                          <p dangerouslySetInnerHTML={{ __html: data.description_team }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </div>
            </Row>
          </Container>
        </div>
      </section>
    );
  }
}

export default Presentation;
